
/**
 * @name: talentManage
 * @author: lili
 * @date: 2023-06-02 10:27
 * @description：分销管理-渠道方管理
 * @update: 2023-06-02 10:27
 */
import {Component, Vue} from "vue-property-decorator";
import {
  channelBatchAddApi,
  channelRemoveApi,
  channelStatusUpdateApi,
  queryChannelByPageApi,
  queryCustomListApi,
  querySalesOrderListApi,
} from "@/apis/distributionManage/channelManage";
import {Message} from "element-ui";
import {
  IChannel,
  IChannelByPageQuery,
  IOrderCustom,
  IOrderCustomQuery,
  ITalentCustom,
  ITalentCustomQuery
} from "@/apis/distributionManage/channelManage/types";
import {queryUserListApi} from "@/apis/distributionManage/coachManage";
import {ICoachByPageList} from "@/apis/distributionManage/coachManage/types";
import {ICrudOption} from "@/types/m-ui-crud";
import {deepCopy} from "@/utils/common";

@Component({})
export default class UserIndex extends Vue {

  queryForm: IChannelByPageQuery = {
    page: 1,
    limit: 10
  }
  total: number = 0
  // 弹窗开关
  dialogVisible = false
  // titleList名称列表
  titleList = ['会员库', '渠道方库']
  // 会员库
  transferData = []
  // 渠道方库
  ids = []

  tableData: IChannel[] = []

  // 新增修改表单
  modelForm: IChannel = {
    abilityStatus: 1
  }


  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "渠道方ID",
        prop: "id",
        align: 'center',
        width: 200,
      },
      {
        label: "渠道方名称",
        prop: "userName",
        align: 'center',
        search: true,
        placeholder: "渠道方名称",
      },
      {
        label: "手机号",
        prop: "phone",
        align: 'center',
        width: 200,
      },
      {
        label: "渠道方获客",
        prop: "customNumber",
        align: 'center',
        slot: true,
      },
      {
        label: "客户消费",
        prop: "consumeTotal",
        align: 'center',
      },
      {
        label: "渠道方消费订单",
        prop: "consumeOrderNumber",
        align: 'center',
        width: 150,
        slot: true,
      },
      {
        label: "佣金",
        prop: "commission",
        align: 'center',
      },
      {
        label: "状态",
        prop: "abilityStatus",
        align: 'center',
        search: true,
        slot: true,
        width: 150,
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
      },
      {
        label: "操作",
        prop: "operate",
        align: 'center',
        width: 200,
        slot: true
      },
    ]
  }
  /***
   * customCrudOption
   * 渠道方获客
   */
  customForm: ITalentCustomQuery = {
    page: 1,
    limit: 10
  }
  customList: ITalentCustom[] = [];
  customTotal = 0;
  customDialog = false;

  customCrudOption: ICrudOption = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "用户ID",
        prop: "id",
        align: 'center',
        width: 200,
      },
      {
        label: "会员名称",
        prop: "userName",
        align: 'center',
      },
      {
        label: "会员账号",
        prop: "userName",
        align: 'center',
      },
      {
        label: "手机号",
        prop: "phone",
        align: 'center',
        width: 180,
      },
      {
        "label": "获客时间",
        "prop": "channelTime",
        "align": "center",
        "width": "180",
        "type": "daterange",
        "search": true
      },
      {
        label: "会员等级",
        prop: "userGrade",
        align: 'center',
      },
      {
        label: "已购商品",
        prop: "buyGoodsNumber",
        align: 'center'
      },
      {
        label: "已购课程",
        prop: "buyCourseNumber",
        align: 'center',
      },
      {
        label: "已报名赛事",
        prop: "enrollGameNumber",
        align: 'center'
      },
      {
        label: "已购旅团",
        prop: "enrollTourNumber",
        align: 'center',
      },
      {
        label: "已获提成",
        prop: "obtainCommission",
        align: 'center',
      },
    ]
  }
  /***
   * orderCrudOption
   * 客户消费订单
   */

  customOrderForm: IOrderCustomQuery = {
    page: 1,
    limit: 10
  }
  customOrderList: IOrderCustom[] = [];
  customOrderTotal = 0;
  customOrderDialog = false;
  orderCrudOption: ICrudOption = {
    searchBox: true,
    menu: false,
    column: [
      {
        label: "订单编号",
        prop: "id",
        align: 'center',
        "search": true,
        width: 200,
      },
      {
        label: "订单类型",
        prop: "orderTypes",
        align: 'center',
        type: "select",//订单类型: 1=商城订单 2=旅游订单 3=赛事订单 4=1v1课程订单 5=网红订单 6=视频课程订单
        dicData: [{label: '视频课程订单', value: 6}, {label: '网红订单', value: 5}, {
          label: '1v1课程订单',
          value: 4
        }, {label: '赛事订单', value: 3}, {label: '旅游订单', value: 2}, {label: '商城订单', value: 1}],
        "search": true,
      },
      {
        label: "订单金额",
        prop: "payPrice",
        align: 'center',
      },
      {
        label: "下单客户",
        prop: "userName",
        align: 'center',
      },
      {
        label: "佣金",
        prop: "userCommission",
        align: 'center',
      },
      {
        "label": "下单时间",
        "prop": "payTime",
        "align": "center",
        "width": "180",
        "type": "daterange",
        "search": true
      },
    ]
  }

  /**
   * 获取数据
   */
  getList() {
    queryChannelByPageApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.total = e.total
    });
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: ICoachByPageList, val: number) {
    channelStatusUpdateApi({id: row.id, abilityStatus: val}).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      }
    })
  }

  /**
   * 打开弹窗
   */
  async openAdd() {
    this.ids = []
    queryUserListApi(1).then(e => {
      this.transferData = e.map((item: any) => {
        return {
          key: item.id,
          label: `(${item.phone}) ${item.userName}`
        }
      })
      this.dialogVisible = true
    })
  }

  /**
   * 删除
   * @param id
   */
  handleRemove(id: string) {
    this.$confirm('是否确认删除该渠道方?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      channelRemoveApi(id).then(e => {
        if (e) {
          Message.success("删除成功!")
          this.getList()
        }
      })
    }).catch(() => {
    });
  }

  /**
   * 新增
   */
  handleAffirm() {
    if (this.ids.length > 0) {
      let ids = this.ids.join(',')
      channelBatchAddApi(ids).then(e => {
        if (e) {
          Message.success("新增成功!")
          this.dialogVisible = false
          this.getList()
        }
      })
    } else {
      Message.error("请先选择渠道方")
    }
  }

  /**
   * 渠道方获客弹窗
   * @param row
   */
  openCustomDialog(row: ITalentCustom) {
    this.customForm.id = row.id;
    this.getCustomList();
    this.customDialog = true
  }

  /**
   * 渠道方获客列表
   * @param row
   */
  getCustomList() {
    const query: ITalentCustomQuery = deepCopy(this.customForm)
    if (query.channelTime && query.channelTime.length === 2) {
      query.bindTimeStart = query.channelTime[0]
      query.bindTimeEnd = query.channelTime[1]
    } else {
      query.bindTimeStart = ""
      query.bindTimeEnd = ""
    }
    delete query.channelTime
    queryCustomListApi(query).then(e => {
      this.customList = e.list;
      this.customTotal = e.total
    })
  }

  /**
   * 渠道方获客订单弹窗
   * @param row
   */
  openCustomOrderDialog(row: ITalentCustom) {
    this.customOrderForm.userId = row.id;
    this.getCustomOrderList();
    this.customOrderDialog = true
  }

  /**
   * 渠道方获客订单列表
   * @param row
   */
  getCustomOrderList() {
    const query: IOrderCustomQuery = deepCopy(this.customOrderForm)
    if (query.payTime && query.payTime.length === 2) {
      query.startTime = query.payTime[0]
      query.endTime = query.payTime[1]
    } else {
      query.startTime = ""
      query.endTime = ""
    }
    delete query.payTime
    querySalesOrderListApi(query).then(e => {
      this.customOrderList = e.list;
      this.customOrderTotal = e.total
    })
  }

  created() {
    this.getList();
  }
}
