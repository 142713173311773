/**
 * @name: index
 * @author: Gzm
 * @date: 2023-05-30 10:44
 * @description：分销管理-教练管理API
 * @update: 2023-05-30 10:44
 */
import {IPageRes} from "@/apis/page";
import {get, postJ} from "@/request";
import {
  IChannel,
  ITalentCustom,
  ITalentCustomQuery,
  IOrderCustomQuery,
  IOrderCustom, IChannelByPageQuery
} from "@/apis/distributionManage/channelManage/types";

/**
 * 渠道方管理分页查询列表
 */
export const queryChannelByPageApi = (param:IChannelByPageQuery)=> get<IPageRes<IChannel[]>>("/golf/channelManage/queryChannelByPage", param)

/**
 * 渠道方管理删除
 */
export const channelRemoveApi = (id:string)=> get(`/golf/channelManage/remove/${id}`)

/**
 * 查询渠道方客户订单列表
 */
export const querySalesOrderListApi = (param:IOrderCustomQuery)=> get<IPageRes<IOrderCustom[]>>(`/golf/channelManage/salesOrderList`,param)

/**
 * 查询渠道方客户列表
 */
export const queryCustomListApi = (param:ITalentCustomQuery)=> get<IPageRes<ITalentCustom[]>>(`/golf/channelManage/queryCustomList`,param)

/**
 * 批量新增渠道方管理
 */
export const channelBatchAddApi = (ids:string)=> postJ(`/golf/channelManage/batchAdd?ids=${ids}`, {})

/**
 * 启用禁用
 */
export const channelStatusUpdateApi = (data: IChannel) => postJ("/golf/channelManage/statusUpdate", data)
